<template>

<div id="LevelExamStart">  
  <Header/>
  <body class="bg-success-soft">
    <nav class="navbar bg-success-soft fixed-top nicole-noshadow">
      <div class="container">
        <!-- <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" data-bs-toggle="modal" data-bs-target="#notice3"> -->
        <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
        </a> 
      </div>
    </nav>
    <div class="nicolespace"></div>
    <div class="container mt-2 mt-md-3 mb-3 mb-md-5">
      <div class="card card-body py-2 green-shadow choose_vh">
        <div class="text-gray text-right" :class="isShowClock ? '' : 'addedHight' ">
          <div v-show="isShowClock" >
            <i class="fal fa-clock me-2"></i>30'
          </div>
        </div>     
        <!--Exam Group 圖片-->
        <div v-show="currExam.exam_style == 'G4' || currExam.exam_style == 'G5' || currExam.exam_style == 'P1' || currExam.exam_style == 'P2' || currExam.exam_style == 'P3'">
          <div class="text-center mt-3">  
              <img v-real-img="examGroupImage" src="assets/img/noback.png" class="green_test_pic rounded-3" style="object-fit: contain;">
          </div>  
        </div>
        <!--Exam Group 音檔-->
        <div v-show="currExam.exam_style == 'G1' || currExam.exam_style == 'G2' || currExam.exam_style == 'G3' || currExam.exam_style == 'G4' || currExam.exam_style == 'G5' || currExam.exam_style == 'P1' || currExam.exam_style == 'P2' || currExam.exam_style == 'P4' || currExam.exam_style == 'P6' || currExam.exam_style == 'Q1' || currExam.exam_style == 'Q2' || currExam.exam_style == 'Q3' || currExam.exam_style == 'Q4'">
          <div class="text-center">
            <img :src="masterAudioPic ? 'assets/img/play.png' : 'assets/img/play_nostar.png' " class="avatar avatar-l" @click="startPlayVoice(currExam.exam_list[0].exam.audio_url_m3u8, $event.target)">        
          </div>   
        </div>
        <!--Exam Group 文字-->
        <div v-show="currExam.exam_style != undefined &&  
          (currExam.exam_style == 'G1' || currExam.exam_style == 'G2' || currExam.exam_style == 'G5' || currExam.exam_style == 'P1' || currExam.exam_style == 'P3' || 
          currExam.exam_style == 'P5' || currExam.exam_style == 'P6' || currExam.exam_style == 'Q1' || currExam.exam_style == 'Q2' || currExam.exam_style == 'Q3' || 
          currExam.exam_style == 'Q6')">
          <!-- eslint-disable vue/no-v-html -->
          <div class="word bigbox3 pt-2 pb-1 ck-content nicole13rem nweight5 lht2" v-html="examGroupName" v-if="currExam.exam_list != undefined && currExam.exam_list.length > 0 && currExam.exam_list[0].exam.eetid == 'sentence'"></div>
          <div class="" v-html="examGroupName" v-if="currExam.exam_list[0].exam.eetid == 'preview'"></div>
          <!--eslint-enable-->
        </div>
        <!-- 選項 -->
        <div v-if="examItemStyle=='text'">
          <div class="text-center choose_mt" >
            <div class="position-relative chooise_mb" v-for="(item, index) in currExam.exam_list[0].item" :key='item.eiid'>
              <div class="badge badge-secondary rounded-3 choose-btn text-simbold chooise text-wrap text-left" 
                  @click="OnItemClick(item.eiid ,item.eid , this)" 
                  :id="'text'+item.eiid">
                <div class="d-flex"> 
                    <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="index==0">A. </span> 
                    <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="index==1">B. </span> 
                    <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="index==2">C. </span> 
                    <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="index==3">D. </span> 
                    <!-- eslint-disable vue/no-v-html --> 
                    <div class="text-mm text-simbold lh18 contentbox" v-html="item.name"></div>
                    <!--eslint-enable-->
                </div>
              </div>
              <img src="assets/img/img008.png" class="avatar avatar-check rounded-0 position-absolute topdowncenter ms-2 hide" :id="'ok'+item.eiid">
              <img src="assets/img/ic_test_fail.png" class="avatar avatar-check rounded-0 position-absolute topdowncenter ms-2 hide" :id="'fail'+item.eiid">
            </div>
            <div class="badge badge-secondary rounded-3 choose-btn text-simbold text-s chooise_mb chooise text-wrap text-left lh-base" @click="OnItemClick(undefined ,currExam.exam_list[0].item[0].eid , this)">不清楚答案 </div>
          </div>
        </div>
        <div v-if="examItemStyle=='image'">
          <div class="text-center picbox mx-auto mt-3 mt-md-4" > 
            <div v-for="(item, index) in currExam.exam_list[0].item" :key='item.eiid' v-if='index%2==0'>
              <div class="d-flex mb-3 mb-md-4" > 
                <div v-if="config && config.old_images && config.old_images=='Y'" class="w-50 me-2 rounded-3 text-right me-1 me-md-3 examItemImage greentestpic" 
                    :style="item.image_url.indexOf('background-image') > -1 ? item.image_url : (item.image_url != '' ? 'background-image: url(data:image/jpeg;base64,'+item.image_url+');' : 'background-image: url(assets/img/noback.png);')" 
                    @data-url="item.eiid"
                    @click="OnItemClick(item.eiid ,item.eid , this)" 
                    :id="'text'+item.eiid" 
                    >
                  <img src="assets/img/white_yes.png" class="avatar avatar-check rounded-0 hide" :id="'ok'+item.eiid"> 
                  <img src="assets/img/white_no.png" class="avatar avatar-check rounded-0 hide" :id="'fail'+item.eiid">
                </div>
                <div v-if="config && config.old_images && config.old_images=='N'" class="w-50 me-2 rounded-3 text-right me-1 me-md-3 examItemImage greentestpic" 
                    :style="item.image_url.indexOf('background-image') > -1 ? item.image_url : (item.image_url != '' ? 'background-image: url('+item.image_url+');' : 'background-image: url(assets/img/noback.png);')" 
                    @data-url="item.eiid"
                    @click="OnItemClick(item.eiid ,item.eid , this)" 
                    :id="'text'+item.eiid" 
                    >
                  <img src="assets/img/white_yes.png" class="avatar avatar-check rounded-0 hide" :id="'ok'+item.eiid"> 
                  <img src="assets/img/white_no.png" class="avatar avatar-check rounded-0 hide" :id="'fail'+item.eiid">
                </div>
                <div v-if="config && config.old_images && config.old_images=='Y' && index+1<currExam.exam_list[0].item.length" class="w-50 ms-2 rounded-3 text-right ms-1 ms-md-3 examItemImage greentestpic" 
                :style="currExam.exam_list[0].item[index+1].image_url.indexOf('background-image') > -1 ? currExam.exam_list[0].item[index+1].image_url : (currExam.exam_list[0].item[index+1].image_url != '' ? 'background-image: url(data:image/jpeg;base64,'+currExam.exam_list[0].item[index+1].image_url+');' : 'background-image: url(assets/img/noback.png);')"  @click="OnItemClick(currExam.exam_list[0].item[index+1].eiid ,currExam.exam_list[0].item[index+1].eid , this)"
                    :id="'text'+currExam.exam_list[0].item[index+1].eiid">
                  <img src="assets/img/white_yes.png" class="avatar avatar-check rounded-0 hide" :id="'ok'+currExam.exam_list[0].item[index+1].eiid"> 
                  <img src="assets/img/white_no.png" class="avatar avatar-check rounded-0 hide" :id="'fail'+currExam.exam_list[0].item[index+1].eiid">
                </div>
                <div v-if="config && config.old_images && config.old_images=='N' && index+1<currExam.exam_list[0].item.length" class="w-50 ms-2 rounded-3 text-right ms-1 ms-md-3 examItemImage greentestpic" 
                :style="currExam.exam_list[0].item[index+1].image_url.indexOf('background-image') > -1 ? currExam.exam_list[0].item[index+1].image_url : (currExam.exam_list[0].item[index+1].image_url != '' ? 'background-image: url('+currExam.exam_list[0].item[index+1].image_url+');' : 'background-image: url(assets/img/noback.png);')" @click="OnItemClick(currExam.exam_list[0].item[index+1].eiid ,currExam.exam_list[0].item[index+1].eid , this)"
                    :id="'text'+currExam.exam_list[0].item[index+1].eiid">
                  <img src="assets/img/white_yes.png" class="avatar avatar-check rounded-0 hide" :id="'ok'+currExam.exam_list[0].item[index+1].eiid"> 
                  <img src="assets/img/white_no.png" class="avatar avatar-check rounded-0 hide" :id="'fail'+currExam.exam_list[0].item[index+1].eiid">
                </div>
              </div> 
            </div> 
            <div class="d-flex mb-3 mb-md-4"> 
              <div class="badge badge-secondary rounded-3 w-100 text-simbold text-s chooise_mb chooise text-wrap text-center lh-base" @click="OnItemClick(undefined ,currExam.exam_list[0].item[0].eid , this)">不清楚答案</div>  
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nicolespace"></div>  
    <!-- Modal 離開彈窗 -->
    <div class="modal fade " id="notice3" tabindex="-1" role="dialog" aria-labelledby="notice3Label" aria-hidden="true">
      <div class="modal-sm modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content nicole-modal"> 
          <div class="modal-header">
            <h4 class="text-center text-lg fw-bold text-dark mb-0">確定要離開嗎？</h4>
          </div>    
          <div class="modal-body">    
            <div class="text-center">
              <h5 class="text-simbold text-s text-dark mb-0">中途離開不會計入學習歷程喔</h5>
            </div>    
          </div>
          <div class="border-top d-flex align-content-center justify-content-between">
            <div class="w-50 text-center border-end py-3">
              <h5 class="text-success text-simbold mb-0" data-bs-dismiss="modal" @click="back()">離開</h5>
            </div>
            <div class="w-50 text-center py-3">
              <h5 class="text-success text-simbold mb-0 " data-bs-dismiss="modal">繼續答題</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 播放器 -->
    <video-player  class="video-player-box"
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"

                 @play="onPlayerPlay($event)"
                 @pause="onPlayerPause($event)"
                 @ended="onPlayerEnded($event)"
                 @waiting="onPlayerWaiting($event)"
                 @playing="onPlayerPlaying($event)"
                 @loadeddata="onPlayerLoadeddata($event)"
                 @timeupdate="onPlayerTimeupdate($event)"
                 @canplay="onPlayerCanplay($event)"
                 @canplaythrough="onPlayerCanplaythrough($event)"
                 @statechanged="playerStateChanged($event)"
                 @ready="playerReadied"
                 v-show="false"
                 >
    </video-player>


  </body>
</div>
</template>


<script>
import Header from '@/components/Header.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import { config } from "@/include/config"; 

export default {
    name: 'LevelExamStart',
    components: {
        Header,
    },
    data () {
      return { 
        // api setting
        setDebugMode:false ,
        apiDebug: true,
        debugMode: false,
        apiName: "",
        form: {},

        // data
          examGroupName: "",  // 題目文字
          examGroupAudio: "", // 音檔URL
          examGroupImage: "", // 圖片URL
          examParent: [],     // 選項
          eid: "", // 當前 Exam ID
          eplid: "",
          huepid: "",
       
          config:"",

          exam: [], // 所有資料
          currExam: [], // 當前題組的資料
          mEid: "", // 選項的判斷參數
          currIndex: -1,
          
          mExamLength: 0, // 題目數量
          countExam: 0,

          examPaperType: 0,

          timer: null,
          time: 0,
          apiPostDebug: true,



        // 考卷類型
          examPaperTypeWordReexc: 2,
          examPaperTypeQuiz: 2,
          examPaperTypeListening: 3,
          examPaperTypeLevel: 4,

        // 單選/複選
          examModeSingle: 1,
          examModeMultiple: 2,

        // Status
          isShowClock: false,
          playCorrectAudio: true,
          isNotCheckWhichAudio: false,
          debug: false,
          isSelect: false,
          isCheckAnswer: false, // 防止快速連點


        query: "",
        examItemStyle: "",

        // 紀錄答案
          recordExamItem: null,
          recordEiidArray: null,
          checkShowNext: null,
          isShowAnswer: true, 

        // 選項的狀態
        selectExamItem: [],

        // 播放器
        isPlayAudio: false, // 是否正再播放音檔
        masterAudioPic: false,
        isAutoPlayAduio: false, // 是否要自動播放音檔
        audioUrlM3u8: "", // 音檔路徑
        lastDom: null,
        isGetAudioToken: false,

        playerOptions: {
          // videojs options
          muted: false,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [],
          poster: "/static/images/author.jpg",
        },
        // 自動播放子題音檔
        currentAudio: -1, // G3 子題有音檔，需要自動播放
        examAudioM3u8: [],


      }
    },
    methods: {
      openLogin(){
        this.$JsBridge.callHandler('openVueLoginView',JSON.stringify({backUrl:"LevelExamIntro", type:""}), this.openLoginCallback);
        this.backUrl = "";
      },
      openLoginCallback(data){

      },
      setOnBack(){
        this.$JsBridge.callHandler('setOnback', '', this.onBack);
      },
      onBack(){
        this.showModal('#notice3');
      },
      back() {
        if (this.$route.params.back != undefined && this.$route.params.back != "" ) {
          this.$router.push(this.$route.params.back);
        } else {
          this.$router.push({
            name: "LevelExamIntro"
          });
        }
      },
      
      getAPI(){
        this.apiName="page_level_exam_start";
        oAC.getAllRecord(oAC.getApiParam(this), {uid:this.$GlobalData.uid} ).then(res => {
          if(res && res.get_page_level_exam_start && res.get_page_level_exam_start.record && res.get_page_level_exam_start.record.length > 0){  
            this.exam = res.get_page_level_exam_start.record;

            this.constructorExam();
            this.InitExam();
            this.RandomExam();
            this.UpdateExam();
            this.InitClickListener();
            this.is_Count = true;
            this.url = undefined;
          }
        }).catch((err)=> {
            oltConsole.log(err);
        });
      },

      // 整理資料
      constructorExam(){
        this.currExam = undefined;
        this.currIndex = -1;
        this.exam_mode = undefined; // 1-一個題目 2-多個題目

        // 紀錄答案
        this.recordExamItem = new Map();
        this.recordEiidArray = new Map();
        // 確認是否要顯示下一題按鈕
        this.checkShowNext = new Map();

        if (this.exam != undefined && this.exam.length > 0) {
            this.examPaperType = this.exam[0].eptid;
            this.exam_mode = this.exam[0].exam_mode;
            this.exam_type = this.exam[0].exam_mode;
            this.exam_style = this.exam[0].exam_style;
            this.eplid = this.exam[0].eplid;
            this.huepid = this.exam[0].huepid;
        }
      },

      InitExam(){
        this.isCheckAnswer = false;
        this.examGroupImage = "";
        this.recordExamItem.clear();
        this.recordEiidArray.clear();
        this.checkShowNext.clear();
      },
      // 隨機出題
      RandomExam() {
        if (this.exam != undefined && this.exam.length > 0) {
            var index = Math.floor(Math.random() * Math.floor(this.exam.length));
            if (index < this.exam.length) {
              this.currExam = this.exam[index];
              this.currIndex = index;
            }
        }
      },
      // 更新題目
      UpdateExam() {
        this.examAudioM3u8 = [];
        this.currentAudio = -1;

        if (this.currExam != undefined) {
          if (this.currExam.exam_list != undefined && this.currExam.exam_list.length > 0) {
              this.mExamLength = this.currExam.exam_list.length;
          } 
          
          // 假如設定題數只有一題, 吃 exam 的 esid
          if (this.currExam.exam_mode == 1) {
              this.currExam.exam_style = (this.currExam.exam_list != undefined && this.currExam.exam_list.length > 0 && this.currExam.exam_list[0].exam != undefined && this.currExam.exam_list[0].exam.esid != undefined) ? this.currExam.exam_list[0].exam.esid : '';
          } 
          // 反之, 吃 exam_group 的 esid
          else {
              this.currExam.exam_style = (this.currExam.exam_group != undefined && this.currExam.exam_group.esid != undefined) ? this.currExam.exam_group.esid : '';
          }

          for (var i = 0; i < this.currExam.exam_list[0].item.length; i++) {
            this.selectExamItem[i]= {
              id: this.currExam.exam_list[0].item[i].eiid,
              ok: false,
              fail: false,
              isCheck: false,
            };
          }

          this.examItemStyle = "text";
          switch (this.currExam.exam_style) {
              case "G1":
                  this.G1(this.currExam);
                  break;
              case "G2":
                  this.G2(this.currExam);
                  break;
              case "G3":
                  this.G3(this.currExam);
                  break;
              case "G4":
                  this.G4(this.currExam);
                  break;
              case "G5":
                  this.G5(this.currExam);
                  break;
              case "P1":
                  this.P1(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "P2":
                  this.P2(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "P3":
                  this.P3(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "P4":
                  this.P4(this.currExam);
                  this.mExamLength = 1;
                  this.examItemStyle = "image";
                  break;
              case "P5":
                  this.P5(this.currExam);
                  this.mExamLength = 1;
                  this.examItemStyle = "image";
                  break;
              case "P6":
                  this.P6(this.currExam);
                  this.mExamLength = 1;
                  this.examItemStyle = "image";
                  break;
              case "Q1":
                  this.Q1(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "Q2":
                  this.Q2(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "Q3":
                  this.Q3(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "Q4":
                  this.Q4(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "Q5":
                  this.Q5(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "Q6":
                  this.Q6(this.currExam);
                  this.mExamLength = 1;
                  break;
              default:
                  isErrorExam = 'Y'
                  break;
          }
          this.isAutoPlay = false;
        }
      },


      // 按鍵監聽 - 選項
      OnItemClick(eiid, eid, exam_type) {
        if (this.isCheckAnswer) {
          return false;
        }
        this.isCheckAnswer = true;

        let tempCurrExam = this.currExam;

        this.mEid = eid;
        this.isSelect = 'Y';
        if (this.currExam.exam_group.is_sample == 'Y') {
          return;
        }
        
        // 選取特效 ---------------------------------------------------
        // 圖片式選項

        if (this.currExam.exam_style == "P4" || this.currExam.exam_style == "P5" || this.currExam.exam_style == "P6") {
            if (this.currExam.exam_mode == this.examModeSingle) {
                // 單選規則
                if (this.recordExamItem.size > 0) {
                  // 假如點選同一個選項，必須取消選取
                  if (this.recordExamItem.has(eiid)) {
                    this.recordExamItem.delete(eiid);
                    this.isSelect = 'N';
                  } else {
                    this.recordExamItem.forEach((value_eid, key_eiid) => {
                        this.recordExamItem.delete(key_eiid);
                    });
                    this.recordExamItem.set(eiid, eid);
                    this.isSelect = 'Y';
                  }
                } else {
                  this.recordExamItem.set(eiid, eid);
                  this.isSelect = 'Y';
                }
            } else if (this.currExam.exam_mode == this.examModeMultiple) {
                // 複選規則
                if (this.recordExamItem.has(eiid)) {
                  this.recordExamItem.delete(eiid);
                } else {
                  this.recordExamItem.set(eiid, eid);
                }

                // 確認暫存答案陣列中是否還有當前題目的作答紀錄
                // 假如有的話就要將 isSelect設置成為 Y, 反之則設置成為 N 
                this.isSelect = 'N';
                var tempEid = this.mEid;
                this.recordExamItem.forEach(function(eid, eiid) {
                    if (eid == tempEid) {
                        this.isSelect = 'Y';
                    }
                })
            }

            // 考卷為等級測驗 - 下一步的行為
            if (this.examPaperType == this.examPaperTypeLevel) {
                // 停止計時器
                // this.clearInterval(this.timer);

                // 停止播放音檔
                this.stopPlayVoice();
                this.stopVuePlayer();

                // 清除監聽 - 選項
                // $(".exam-item").attr("onclick", "").unbind("click");

                var playCorrectAudio = false;

                // 選了我不知道的選項
                if (eiid == undefined) {
                  this.IDontKnowTimer();
                }
                // 選擇其他選項
                else {

                    let playCorrectAudio = false;
                    this.isShowAnswer = true;
                    this.isShowConcept = true;
                    
                    // 圖片選項
                    if (this.currExam.exam_style == "P4" || this.currExam.exam_style == "P5" || this.currExam.exam_style == "P6") {
                      playCorrectAudio = this.showCorrectImageItem();
                    }
                    // 文字選項
                    else {
                      // 多少個小題
                      for (var i = 0; i < this.currExam.exam_list.length; i++) {
                        if (this.currExam.exam_list[i].answer != undefined && this.currExam.exam_list[i].answer.length > 0) {
                          // 多少個答案
                          for (var j = 0; j < this.currExam.exam_list[i].answer.length; j++) {
                            // 加上正確的圖示
                            if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                              $("#ok"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("hide");
                              $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("greentestpicborder");
                              $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("badge-secondary");
                              $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).addClass("badge-success");
                              playCorrectAudio = true;
                            } 
                            // 紀錄答案, 並刪除目前紀錄在 MAP 裡面的資料                    
                            if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                              this.recordEiidArray.set(this.currExam.exam_list[i].answer[j]["eiid"], this.currExam.exam_list[i].answer[j]["eid"]);
                              this.recordExamItem.delete(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]));
                            }

                          }                      
                        }
                      }
                      // 假如 MAP 裡面還有剩餘資料， 必須將它標示為錯誤的
                      if (this.recordExamItem.size > 0) {
                        this.recordExamItem.forEach((value_eid, key_eiid, items) => {
                          // 紀錄答案
                          this.recordEiidArray.set(key_eiid, value_eid);
                            $("#fail"+key_eiid).removeClass("hide");
                            $("#text"+key_eiid).removeClass("badge-secondary");
                            $("#text"+key_eiid).removeClass("greentestpicborder");
                            $("#text"+key_eiid).addClass("badge-danger");
                            playCorrectAudio = false;
                        });
                      }
                    }
                    this.currExam = tempCurrExam;

                    if (!this.isShowHistory) {
                      this.saveAnswer();
                    }

                    // 刪除出現的題目
                    this.exam.splice(this.currIndex, 1);

                    if (playCorrectAudio) {
                      this.loadCorrectAudio();
                    } else {
                      this.loadErrorAudio();
                    }
              }
            } 
            // 考卷為其他 - 下一步的行為
            else {
                // 顯示/隱藏下一題按鈕
                // 不能以數量來做為顯示的依據，因為題組的題目是複選題時，會超過
                // 正確做法是 -> 先確認每個題目是否有作答, 假如都有作答就給過 
                // mExamLength : 總題數
                this.countExam = 0;
                var tempEid = this.mEid;
                $(".exam").each(function(a, b) {
                    if (b.id == tempEid) {
                        $("#" + b.id).attr("data", this.isSelect);
                    }
                    if ($("#" + b.id).attr("data") == 'Y') {
                        this.countExam = this.countExam + 1;
                    }
                });

                if (this.countExam == this.mExamLength) {
                    $("#btn-green-next").css("display", "");
                    $("#btn-green-block").css("display", "");
                    $("#btn-gray-next").css("display", "none");
                    $("#btn-gray-block").css("display", "none");
                } else {
                    $("#btn-green-next").css("display", "none");
                    $("#btn-green-block").css("display", "none");
                    $("#btn-gray-next").css("display", "");
                    $("#btn-gray-block").css("display", "");
                }
            }
        }
        // 文字式選項
        else {
          // 選項選取特效=================================================
          if (this.currExam.exam_mode == this.examModeSingle) {
              // 單選規則
              if (this.recordExamItem.size > 0) {

                  // 假如點選同一個選項，必須取消選取
                  if (this.recordExamItem.has(eiid)) {
                      this.recordExamItem.delete(eiid);
                      this.isSelect = 'N';
                  }
                  // 假如點選不同的選項，必須先判斷是否為同一小題
                  // 1. 同一小題, 要取消同一小題的其他選項
                  // 2. 不同小題, 不用理會直接變更為選取狀態
                  else {
                      // 確認目前儲存的資料，是否已有目前的選項
                      var tempEid = this.mEid;
                      this.recordExamItem.forEach((value_eid, key_eiid) => {
                          if (value_eid == tempEid) {
                              this.recordExamItem.delete(key_eiid);
                          }
                      });
                      this.recordExamItem.set(eiid, eid);
                      this.isSelect = 'Y';
                  }
              } else {
                  this.recordExamItem.set(eiid, eid);
                  this.isSelect = 'Y';
              }
          } else if (this.currExam.exam_mode == this.examModeMultiple) {
              // 複選規則
              if (this.recordExamItem.has(eiid)) {
                  this.recordExamItem.delete(eiid);
              } else {
                  this.recordExamItem.set(eiid, eid);
              }
              // 確認暫存答案陣列中是否還有當前題目的作答紀錄
              // 假如有的話就要將 isSelect設置成為 Y, 反之則設置成為 N 
              this.isSelect = 'N';
              var tempEid = this.mEid;
              this.recordExamItem.forEach(function(eid, eiid) {
                  if (eid == tempEid) {
                      this.isSelect = 'Y';
                  }
              })
          }


          // 等級測驗 - 下一步的行為
          if (this.examPaperType == this.examPaperTypeLevel) {
              // 停止播放音檔
              this.stopPlayVoice();
              this.stopVuePlayer();

              var playCorrectAudio = false;

              // 選了我不知道的選項
              if (eiid == undefined) {
                this.IDontKnowTimer();
              }
              // 選擇其他選項
              else {
                var exam = this.currExam;

                // 多少個小題
                for (var i = 0; i < this.currExam.exam_list.length; i++) {
                  if (this.currExam.exam_list[i].answer != undefined && this.currExam.exam_list[i].answer.length > 0) {
                    // 多少個答案
                    for (var j = 0; j < this.currExam.exam_list[i].answer.length; j++) {
                      // 加上正確的圖示
                      if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                        for (var k = 0; k < this.selectExamItem.length; k++) {
                          if (this.selectExamItem[k].id == this.currExam.exam_list[i].answer[j]["eiid"]) {
                            $("#ok"+this.selectExamItem[k].id).removeClass("hide");
                            $("#text"+this.selectExamItem[k].id).removeClass("badge-secondary");
                            $("#text"+this.selectExamItem[k].id).addClass("badge-success");
                          }
                        }
                        playCorrectAudio = true;
                      }

                      // 紀錄答案, 並刪除目前紀錄在 MAP 裡面的資料                    
                      if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                        this.recordEiidArray.set(this.currExam.exam_list[i].answer[j]["eiid"], this.currExam.exam_list[i].answer[j]["eid"]);
                        this.recordExamItem.delete(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]));
                      }
                    }                      
                  }
                }


                // 假如 MAP 裡面還有剩餘資料， 必須將它標示為錯誤的
                if (this.recordExamItem.size > 0) {
                  var tempArr = this.selectExamItem;
                  this.recordExamItem.forEach((value_eid, key_eiid, items) => {
                    // 紀錄答案
                    this.recordEiidArray.set(key_eiid, value_eid);
                    if (tempArr.length > 0) {
                      for (var i = 0; i < tempArr.length; i++) {
                        if (tempArr[i].id == key_eiid) {
                          $("#fail"+key_eiid).removeClass("hide");
                          $("#text"+key_eiid).removeClass("badge-secondary");
                          $("#text"+key_eiid).addClass("badge-danger");
                          playCorrectAudio = false;
                        }
                      }    
                    }
                  });
                }


                // 儲存作答紀錄
                if (this.recordEiidArray.size > 0) {
                  var tempExam = [];
                  // this.apiName = "page_level_exam_start_answer";
                  this.form["uid"] = this.$GlobalData.uid;
                  var postExamAnswer2 = this.postExamAnswer2;

                  this.recordEiidArray.forEach((value_eid, key_eiid, item) => {

                    tempExam = {
                      "egid": JSON.stringify(this.currExam.exam_group.egid),
                      "eid": JSON.stringify(value_eid),
                      "eiid": JSON.stringify(key_eiid),
                      "result_type": "id"
                    };


                    postExamAnswer2(tempExam);
                  });
                }

                // 刪除出現的題目
                this.exam.splice(this.currIndex, 1);

                if (playCorrectAudio) {
                  this.loadCorrectAudio();
                } else {
                  this.loadErrorAudio();
                }
              }
          } 
          // 其他測驗 - 下一步的行為
          else {
              // 顯示/隱藏下一題按鈕
              // 不能以數量來做為顯示的依據，因為題組的題目是複選題時，會超過
              // 正確做法是 -> 先確認每個題目是否有作答, 假如都有作答就給過 
              // mExamLength : 總題數
              this.countExam = 0;
              var tempEid = this.mEid;
              $(".exam").each(function(a, b) {
                  if (b.id == tempEid) {
                      $("#" + b.id).attr("data", this.isSelect);
                  }
                  if ($("#" + b.id).attr("data") == 'Y') {
                      this.countExam = this.countExam + 1;
                  }
              });

              if (this.countExam == this.mExamLength) {
                  $("#btn-green-next").css("display", "");
                  $("#btn-green-block").css("display", "");
                  $("#btn-gray-next").css("display", "none");
                  $("#btn-gray-block").css("display", "none");
              } else {
                  $("#btn-green-next").css("display", "none");
                  $("#btn-green-block").css("display", "none");
                  $("#btn-gray-next").css("display", "");
                  $("#btn-gray-block").css("display", "");
              }
          }
        }

        if (this.examPaperType != this.examPaperTypeLevel){
          this.isCheckAnswer = true;
        }
      },
      // 初始化按鈕監聽
      InitClickListener() {
        // Exam Group Audio
        $("#exam_group_audio").on('click', function(event) {
            $(this).attr('data-is-play', 'N');
            this.startPlayVoice();
        })
      },


      // 題目各種題型
        /** 多個題目, emid = 2
         * Exam Group - 音檔、文字
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - X
         */
        G1(examList) {
            if (examList == undefined) return;

            // Exam Group 文字    
            this.examGroupName = examList.exam_group.name;

            // Exam Group 圖片
            this.examGroupImage = "";

            // Exam Group 音檔
            // this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
            this.audioUrlM3u8 = "";
            if (examList.exam_list != undefined && examList.exam_list.length > 0 && examList.exam_list[0].exam != undefined && examList.exam_list[0].exam.audio_url_m3u8 != undefined){
              this.audioUrlM3u8 = examList.exam_list[0].exam.audio_url_m3u8;
              this.startPlayVoice(this.audioUrlM3u8, "");
            }
            

            // 取得總題數
            this.mExamLength = examList.exam_list.length;
        },

        /** 多個題目, emid = 2
         * Exam Group - 音檔、音檔文字
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - X
         */
        G2(examList) {
          if (examList == undefined) return;

          // Exam Group 文字    
          this.examGroupName = examList.exam_group.name;

          // Exam Group 圖片
          this.examGroupImage = "";

          // Exam Group 音檔
            // this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
            this.audioUrlM3u8 = "";
            if (examList.exam_list != undefined && examList.exam_list.length > 0 && examList.exam_list[0].exam != undefined && examList.exam_list[0].exam.audio_url_m3u8 != undefined){
              this.audioUrlM3u8 = examList.exam_list[0].exam.audio_url_m3u8;
              this.startPlayVoice(this.audioUrlM3u8, "");
            }

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
          if (examList.exam_list.length > 0) {
            for (var i = 0; i < examList.exam_list.length; i++) {
              if (examList.exam_list[i].exam.esid == "Q3" && examList.exam_list[i].exam.audio_url_m3u8.length > 0) {
                this.examAudioM3u8[i] = examList.exam_list[i].exam.audio_url_m3u8;
              }
            }
          }
        },

        /**
         * Exam Group - 音檔
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - X
         */
        G3(examList) {
          if (examList == undefined) return;

          // Exam Group 文字    
          this.examGroupName = "";

          // Exam Group 圖片
          this.examGroupImage = "";

          // Exam Group 音檔
            // this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
            this.audioUrlM3u8 = "";
            if (examList.exam_list != undefined && examList.exam_list.length > 0 && examList.exam_list[0].exam != undefined && examList.exam_list[0].exam.audio_url_m3u8 != undefined){
              this.audioUrlM3u8 = examList.exam_list[0].exam.audio_url_m3u8;
              this.startPlayVoice(this.audioUrlM3u8, "");
            }

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
          if (examList.exam_list.length > 0) {
            for (var i = 0; i < examList.exam_list.length; i++) {
              if (examList.exam_list[i].exam.esid == "Q3" && examList.exam_list[i].exam.audio_url_m3u8.length > 0) {
                this.examAudioM3u8[i] = examList.exam_list[i].exam.audio_url_m3u8;
              }
            }
          }
        },

        /** 圖片、音檔*/
        G4(examList) {
          if (examList == undefined) return;

          // Exam Group 文字    
          this.examGroupName = "";

          // Exam Group 圖片
          this.examGroupImage = examList.exam_group.egid_image_url;

          // Exam Group 音檔
            // this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
            this.audioUrlM3u8 = "";
            if (examList.exam_list != undefined && examList.exam_list.length > 0 && examList.exam_list[0].exam != undefined && examList.exam_list[0].exam.audio_url_m3u8 != undefined){
              this.audioUrlM3u8 = examList.exam_list[0].exam.audio_url_m3u8;
              this.startPlayVoice(this.audioUrlM3u8, "");
            }

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
          if (examList.exam_list.length > 0) {
            for (var i = 0; i < examList.exam_list.length; i++) {
              if (examList.exam_list[i].exam.esid == "Q3" && examList.exam_list[i].exam.audio_url_m3u8.length > 0) {
                this.examAudioM3u8[i] = examList.exam_list[i].exam.audio_url_m3u8;
              }
            }
          }
        },

        /** 圖片、音檔、文字 */
        G5(examList) {
          if (examList == undefined) return;
          
          // Exam Group 文字    
          this.examGroupName = examList.exam_group.name;

          // Exam Group 圖片
          this.examGroupImage = examList.exam_group.egid_image_url;

          // Exam Group 音檔
            // this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
            this.audioUrlM3u8 = "";
            if (examList.exam_list != undefined && examList.exam_list.length > 0 && examList.exam_list[0].exam != undefined && examList.exam_list[0].exam.audio_url_m3u8 != undefined){
              this.audioUrlM3u8 = examList.exam_list[0].exam.audio_url_m3u8;
              this.startPlayVoice(this.audioUrlM3u8, "");
            }

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
        },

        /** 單個題目, emid = 1
         * Exam Group - 圖片、音檔、文字
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - O
         */
        P1(examList) {
          if (examList == undefined) return;
          
          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = examList.exam_list[0].exam['name'];

            // Exam Group 圖片
            this.examGroupImage = examList.exam_list[0].exam['image_url'];

            // Exam Group 音檔
            // this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
            this.audioUrlM3u8 = "";
            if (examList.exam_list != undefined && examList.exam_list.length > 0 && examList.exam_list[0].exam != undefined && examList.exam_list[0].exam.audio_url_m3u8 != undefined){
              this.audioUrlM3u8 = examList.exam_list[0].exam.audio_url_m3u8;
              this.startPlayVoice(this.audioUrlM3u8, "");
            }
          }
        },

        /**
         * Exam Group - 圖片、音檔
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - O
         */
        P2(examList) {
          if (examList == undefined) return;
          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = "";

            // Exam Group 圖片
            this.examGroupImage = examList.exam_list[0].exam['image_url'];

            // Exam Group 音檔
            // this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
            this.audioUrlM3u8 = "";
            if (examList.exam_list != undefined && examList.exam_list.length > 0 && examList.exam_list[0].exam != undefined && examList.exam_list[0].exam.audio_url_m3u8 != undefined){
              this.audioUrlM3u8 = examList.exam_list[0].exam.audio_url_m3u8;
              this.startPlayVoice(this.audioUrlM3u8, "");
            }
          }
        },

        /**
         * Exam Group - 圖片、文字
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - O
         */
        P3(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = examList.exam_list[0].exam['name'];

            // Exam Group 圖片
            this.examGroupImage = examList.exam_list[0].exam['image_url'];

            // Exam Group 音檔
            this.audioUrlM3u8 = "";
          }
        },

        /**
         * Exam Group - 音檔
         * Exam - X
         * Exam Item - 圖片
         * Exam Concept - O
         */
        P4(examList) { 
          if (examList == undefined) return;
          if (examList.exam_list.length > 0) { 
            // Exam Group 文字
            this.examGroupName = '';

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            // this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
            this.audioUrlM3u8 = "";
            if (examList.exam_list != undefined && examList.exam_list.length > 0 && examList.exam_list[0].exam != undefined && examList.exam_list[0].exam.audio_url_m3u8 != undefined){
              this.audioUrlM3u8 = examList.exam_list[0].exam.audio_url_m3u8;
              this.startPlayVoice(this.audioUrlM3u8, "");
            }
          }
        },

        /**
         * Exam Group - 文字
         * Exam - 文字
         * Exam Item - 圖片
         * Exam Concept - O
         */
        P5(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = examList.exam_list[0].exam['name'];

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            this.audioUrlM3u8 = '';
          }
        },

        /**
         * Exam Group - 文字
         * Exam - 文字
         * Exam Item - 圖片
         * Exam Concept - O
         */
        P6(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {


            // Exam Group 文字    
            this.examGroupName = examList.exam_list[0].exam['name'];

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            // this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
            this.audioUrlM3u8 = "";
            if (examList.exam_list != undefined && examList.exam_list.length > 0 && examList.exam_list[0].exam != undefined && examList.exam_list[0].exam.audio_url_m3u8 != undefined){
              this.audioUrlM3u8 = examList.exam_list[0].exam.audio_url_m3u8;
              this.startPlayVoice(this.audioUrlM3u8, "");
            }
          }
        },

        /**
         * Exam Group - 文字、音檔
         * Exam - 文字
         * Exam Item - 文字
         * Exam Concept - X
         */
        Q1(examList) {
          if (examList == undefined) return;
          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = examList.exam_list[0].exam['name'];

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            // this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
            this.audioUrlM3u8 = "";
            if (examList.exam_list != undefined && examList.exam_list.length > 0 && examList.exam_list[0].exam != undefined && examList.exam_list[0].exam.audio_url_m3u8 != undefined){
              this.audioUrlM3u8 = examList.exam_list[0].exam.audio_url_m3u8;
              this.startPlayVoice(this.audioUrlM3u8, "");
            }
          }
        },

        /**
         * Exam Group - 音檔、文字
         * Exam - 無
         * Exam Item - 文字
         * Exam Concept - X
         */
        Q2(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = examList.exam_list[0].exam['name'];

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            // this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
            this.audioUrlM3u8 = "";
            if (examList.exam_list != undefined && examList.exam_list.length > 0 && examList.exam_list[0].exam != undefined && examList.exam_list[0].exam.audio_url_m3u8 != undefined){
              this.audioUrlM3u8 = examList.exam_list[0].exam.audio_url_m3u8;
              this.startPlayVoice(this.audioUrlM3u8, "");
            }
          }
        },

        /**
         * Exam Group - 音檔、文字
         * Exam - 無
         * Exam Item - 文字
         * Exam Concept - O
         */
        Q3(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = examList.exam_list[0].exam['name'];

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            // this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
            this.audioUrlM3u8 = "";
            if (examList.exam_list != undefined && examList.exam_list.length > 0 && examList.exam_list[0].exam != undefined && examList.exam_list[0].exam.audio_url_m3u8 != undefined){
              this.audioUrlM3u8 = examList.exam_list[0].exam.audio_url_m3u8;
              this.startPlayVoice(this.audioUrlM3u8, "");
            }
          }
        },

        /**
         * Exam Group - 音檔
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - O
         */
        Q4(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = '';

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            // this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
            this.audioUrlM3u8 = "";
            if (examList.exam_list != undefined && examList.exam_list.length > 0 && examList.exam_list[0].exam != undefined && examList.exam_list[0].exam.audio_url_m3u8 != undefined){
              this.audioUrlM3u8 = examList.exam_list[0].exam.audio_url_m3u8;
              this.startPlayVoice(this.audioUrlM3u8, "");
            }
          }
        },

        /**
         * Exam Group - X
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - O
         */
        Q5(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = '';

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            this.audioUrlM3u8 = '';
          }
        },

        /**
         * Exam Group - 文字(填空))
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - O
         */
        Q6(examList) {
          if (examList == undefined) return;

          // Exam Group 文字    
          this.examGroupName = examList.exam_list[0].exam['name'];

          // Exam Group 圖片
          this.examGroupImage = '';

          // Exam Group 音檔
          this.audioUrlM3u8 = '';
        },

      // 選項
        /** 名稱: 多題型  文字式  選項
         * currExam : 目前的大題 (Exam Group)
         * exam_object : 目前的小題
         * query : 要新增的 HTML 內容
         */
        MultipleExamTextItem(currExam, exam_object, query) {
            query += '<div class="bigbox2">';
            var examItemNumber = 0;
            var eid = 0;
            var etid = 0;
            var eiid = 0;
            var item_name = "";
            var examItemText = "";
            exam_object.item.forEach((item_object, item_index) => {
                eid = item_object.eid;
                etid = currExam.exam_list[0].exam['etid'];
                eiid = item_object.eiid;
                item_name = item_object.name;
                switch (examItemNumber) {
                    case 1:
                        examItemText = 'B.&thinsp;';
                        break;
                    case 2:
                        examItemText = 'C.&thinsp;';
                        break;
                    case 3:
                        examItemText = 'D.&thinsp;';
                        break;
                    default:
                        examItemText = 'A.&thinsp;';
                        break;
                }
                query += '<div class="py-2">';
                query += '  <div class="col-12  text-center px-0" style="">';
                query += '    <a class="buttom_white text-muted text-left p-2 p-md-2" style="height:auto; text-decoration:none;" href="javascript: void(0)" id="exam-item-' + eiid + '" onclick="OnItemClick(' + eiid + ',' + eid+ ',' + etid + ')">';
                query += '      <div class="d-flex"> ';
                query += '        <span class="col-1 pl-1 text-center p-md-0 nicole11rem nweight5">' + examItemText + '</span> ';
                query += '        <span class="col-11 pl-0  text-left  ml-1 ml-md-0 nicole11rem nweight5">' + item_name +'</span>';
                query += '      </div>';
                query += '    </a>';
                query += '  </div>';
                query += '</div>';
                
                examItemNumber++;
            });
            return query;
        },

        /** 名稱: 單題型  文字式  選項
         * currExam : 目前的大題 (Exam Group)
         * exam_object : 目前的小題
         * query : 要新增的 HTML 內容
         */
        OneExamTextItem(currExam, exam_object, query) {
            query += '<div class="text-center choose_mt">';
            var examItemNumber = 0;
            var eid = 0;
            var etid = 0;
            var eiid = 0;
            var item_name = "";
            var examItemText = "";
            exam_object.item.forEach((item_object, item_index) => {
                eid = item_object.eid;
                etid = currExam.exam_list[0].exam['etid'];
                eiid = item_object.eiid;
                item_name = item_object.name;
                switch (examItemNumber) {
                    case 1:
                        examItemText = 'B.&thinsp;';
                        break;
                    case 2:
                        examItemText = 'C.&thinsp;';
                        break;
                    case 3:
                        examItemText = 'D.&thinsp;';
                        break;
                    default:
                        examItemText = 'A.&thinsp;';
                        break;
                }
                
                query += '<div class="position-relative">';
                query += '  <div class="badge badge-secondary rounded-3 choose-btn text-simbold text-s chooise_mb chooise text-wrap text-center lh-base" id="exam-item-' + eiid + '" onclick="OnItemClick(' + eiid + ',' + eid+ ',' + etid + ')">';
                query += '    <span class="me-1">' + examItemText + '</span>' + item_name;
                query += '  </div>';
                query += '</div>';

                examItemNumber++;
            });
     
            return query;
        },

        /** 名稱: 多題型  圖片式  選項
         * currExam : 目前的大題 (Exam Group)
         * exam_object : 目前的小題
         * examNumber : 目前編號
         * query : 要新增的 HTML 內容
         */
        MultipleExamImageItem(currExam, exam_object, examNumber, query) {
            query += '<div class="task_pic_body">';
            var examItemNumber = 0;
            var eid = 0;
            var etid = 0;
            var eiid = 0;
            var item_name = "";
            var examItemText = "";
            exam_object.item.forEach((item_object, item_index) => {
                eid = item_object.eid;
                etid = currExam.exam_list[0].exam['etid'];
                eiid = item_object.eiid;
                item_name = item_object.name;
                switch (examItemNumber) {
                    case 0:
                        examItemText = 'A.';
                        break;
                    case 1:
                        examItemText = 'B.';
                        break;
                    case 2:
                        examItemText = 'C.';
                        break;
                    case 3:
                        examItemText = 'D.';
                        break;
                    default:
                        examItemText = 'A.';
                        break;
                }
				
			if(config && config.old_images && config.old_images=="Y") 
			{  		 
                var div_image = (currExam.exam_list[examNumber].item[examItemNumber].image_url != undefined && currExam.exam_list[examNumber].item[examItemNumber].image_url.length > 0) ? 'background-size:100% 100%; background-repeat:no-repeat; background-image: url(data:image/png;base64,' + currExam.exam_list[examNumber].item[examItemNumber].image_url + ')' : 'background-color:#D8D8D8; background-repeat:no-repeat; background-image: url() ;background-position: center;';
                query += '<div class="py-2">';
                query += '  <div class="col-12  text-center px-0">';
                query += '    <a class="task_white exam-item" id="exam-item-' + eiid + '" href="#" style="' + div_image + '"  onclick="OnItemClick(' + eiid + ',' + eid + ',' + etid + ')"></a>';
                query += '  </div>';
                query += '</div>';
			}
			else
			{ 
                var div_image = (currExam.exam_list[examNumber].item[examItemNumber].image_url != undefined && currExam.exam_list[examNumber].item[examItemNumber].image_url.length > 0) ? 'background-size:100% 100%; background-repeat:no-repeat; background-image: url(' + currExam.exam_list[examNumber].item[examItemNumber].image_url + ')' : 'background-color:#D8D8D8; background-repeat:no-repeat; background-image: url() ;background-position: center;';
                query += '<div class="py-2">';
                query += '  <div class="col-12  text-center px-0">';
                query += '    <a class="task_white exam-item" id="exam-item-' + eiid + '" href="#" style="' + div_image + '"  onclick="OnItemClick(' + eiid + ',' + eid + ',' + etid + ')"></a>';
                query += '  </div>';
                query += '</div>';
			}
			
                examItemNumber++;
            });
         
            return query;
        },

        /** 名稱: 單題型  圖片式  選項
         * currExam : 目前的大題 (Exam Group)
         * exam_object : 目前的小題
         * query : 要新增的 HTML 內容
         */
        OneExamImageItem(currExam, exam_object, query) {
            query += '<div class="task_pic_body">';
            var examItemNumber = 0;
            var eid = 0;
            var etid = 0;
            var eiid = 0;
            var examItemText = "";
            exam_object.item.forEach((item_object, item_index) => {
                eid = item_object.eid;
                etid = currExam.exam_list[0].exam['etid'];
                eiid = item_object.eiid;
                switch (examItemNumber) {
                    case 0:
                        examItemText = 'A.';
                        break;
                    case 1:
                        examItemText = 'B.';
                        break;
                    case 2:
                        examItemText = 'C.';
                        break;
                    case 3:
                        examItemText = 'D.';
                        break;
                    default:
                        examItemText = 'A.';
                        break;
                }
					
			if(config && config.old_images && config.old_images=="Y") 
			{  	
			
                var div_image = (currExam.exam_list[0].item[examItemNumber].image_url != undefined && currExam.exam_list[0].item[examItemNumber].image_url.length > 0) ? 'background-size:100% 100%; background-repeat:no-repeat; background-image: url(data:image/png;base64,' + currExam.exam_list[0].item[examItemNumber].image_url + ')' : 'background-color:#D8D8D8; background-repeat:no-repeat; background-image: url() ;background-position: center;';


                query += '<div class="col-12 py-2 px-0 textCenter" style="">';
                query += '<div class="task_white exam-item" id="exam-item-' + eiid + '" style="' + div_image + '" onclick="OnItemClick(' + eiid + ',' + eid + ',' + etid + ')">';
                query += '</div>';
                query += '</div>';
			}
			else
			{
			
                var div_image = (currExam.exam_list[0].item[examItemNumber].image_url != undefined && currExam.exam_list[0].item[examItemNumber].image_url.length > 0) ? 'background-size:100% 100%; background-repeat:no-repeat; background-image: url(' + currExam.exam_list[0].item[examItemNumber].image_url + ')' : 'background-color:#D8D8D8; background-repeat:no-repeat; background-image: url() ;background-position: center;';


                query += '<div class="col-12 py-2 px-0 textCenter" style="">';
                query += '<div class="task_white exam-item" id="exam-item-' + eiid + '" style="' + div_image + '" onclick="OnItemClick(' + eiid + ',' + eid + ',' + etid + ')">';
                query += '</div>';
                query += '</div>';
			}
			
                examItemNumber++;
            });
        
            return query;
        },

      // 題目版型
        /** 名稱: 題目解析
         * eid : 目前的小題ID (Exam)
         * exam_object : 目前的小題
         * query : 要新增的 HTML 內容
         */
        ShowConcept(query, exam_object, eid) {
            if (this.examPaperType != this.examPaperTypeLevel) {
                if (exam_object != undefined && exam_object.concept != undefined && exam_object.concept.length > 0 && exam_object.concept[0].name != undefined && exam_object.concept[0].name.length > 0) {
                query += '<div class="bigbox3 exam-concept hide">';
                query += '  <div class="word text-left ">';
                query += '    <h4 class="mt-2 nicole12rem">題目解析</h4>';
                query += '    <h3 class="nicole1rem nweight5" id="exam-concept-text-' + eid + '">' + exam_object.concept[0].name + '</h3>';
                query += '  </div>';
                query += '</div>';
                }
            }
            return query;
        },

        /** 名稱: Exam Group 文字
         * tempExamList : Exam Group 資料
         * show : 是否要顯示
         */
        SetExamGroupText(name, show) {
            if (show == 'Y') {
                $("#exam_group_name").html(name);
                $("#exam_group_name").removeClass("hide");
                
                // ZEN-2107 針對不同CSS做顯示
                if (this.examPaperType == this.examPaperTypeLevel && this.currExam != undefined && 
                    this.currExam.exam_list != undefined && this.currExam.exam_list.length > 0 && this.currExam.exam_list[0].exam != undefined && 
                    this.currExam.exam_list[0].exam["eetid"] != undefined && this.currExam.exam_list[0].exam["eetid"].length > 0){
                    if (this.currExam.exam_list[0].exam["eetid"] == "sentence"){
                        $("#exam_group_name").attr("class", "word bigbox3 pt-2 pb-1 ck-content nicole13rem nweight5");
                        $("#exam_group_name").attr("style", "line-height:2");
                    } else if (this.currExam.exam_list[0].exam["eetid"] == "preview"){
                        $("#exam_group_name").removeAttr("class");
                        $("#exam_group_name").removeAttr("style");
                    }
                }
                
            } else {
                $("#exam_group_name").html("");
                $("#exam_group_name").addClass("hide");
            }
        },

        /** 名稱: Exam Group 圖片
         * tempExamList : Exam Group 資料
         * show : 是否要顯示
         */
        SetExamGroupImage(image_url, show) {
            if (show == 'Y') {
                if (image_url != undefined && image_url.length > 0) {
						
			if(config && config.old_images && config.old_images=="Y") 
			{  	 
                    $("#exam_group_image").attr("src", "data:image/png;base64, " + image_url);
			}
			else
			{
                    $("#exam_group_image").attr("src", "" + image_url);
			}
                    $("#exam_group_image").removeAttr("style");
                    
                } else {
                    $("#exam_group_image").attr("src", "../img/img_offline_null_bg.png");
                }
                
                $("#exam_group_image_parent").removeClass("hide");
            } else {
                $("#exam_group_image").attr("src", "../img/img_offline_null_bg.png");
                $("#exam_group_image_parent").addClass("hide");
            }
        },


        /** 名稱: Exam 文字
         * eid : Exam ID
         * examText : Exam 編號
         * name : Exam 文字
         * query : 要新增的 HTML 內容
         */
        SetMultipleExamText(eid, number, name, query) {
            if (this.currExam.exam_style == "G1" || this.currExam.exam_style == "G2" || this.currExam.exam_style == "G3"|| this.currExam.exam_style == "G4") {
                query = ' <div class="bigbox3"><hr></div>';
            } else {
                query = ' <div class="bigbox3"></div>';
            }

            query += ' <div class="bigbox3 word">';
            query += '   <h6 class="exam text-left" id="' + eid + '" data="N">Q' + number + '.' + name + '</h6>';
            query += ' </div>';
            query += ' <div class="bigbox2">';
            return query;
        },

        /** 名稱: Exam 文字
         * eid : Exam ID
         * name : Exam 文字
         * query : 要新增的 HTML 內容
         */
        SetOneExamText(eid, name) {
            this.query = '';
            if (this.currExam.exam_mode == 2) {
                this.query = '<div class="bigbox3"><hr></div>';
            }

            if (name != undefined) {
              this.query += '<div class="bigbox3 word"><h6 class="exam text-left" id="' + eid + '" data="N">' + name + '</h6></div>';
            } else {
              this.query += '<div class="bigbox3 word"><h6 class="exam text-left" id="' + eid + '" data="N"></h6></div>';
            }

            return this.query;
        },

      // 將作答紀錄儲存在 DB
      postExamAnswer(callback) {
        this.apiName = "page_level_exam_start";
        oltConsole.log("postExamAnswer");
        oAC.getAllRecord(oAC.getApiParam(this), this.form ).then(callback).catch((err)=> {
              oltConsole.log(err);
        });
      },

      postExamAnswer2(data) {
        this.form = {
          eplid: this.eplid,
          huepid: this.huepid,
          uid: this.$GlobalData.uid,
          exam: data
        };

        this.apiName="page_level_exam_start_answer";
        oAC.postRecord(oAC.getApiParam(this)).then((res)=> {
          
        }).catch((err)=> {
          oltConsole.log(err);
        });
      },


      loadCorrectAudio(){
        oltConsole.log("loadCorrectAudio");
        this.$JsBridge.callHandler('loadCorrectAudioVue',JSON.stringify({backUrl:"LevelExamStart", type:""}), this.getExamAudioCallback);
      },

      loadErrorAudio(){
        oltConsole.log("loadErrorAudio");
        this.$JsBridge.callHandler('loadErrorAudioVue',JSON.stringify({backUrl:"LevelExamStart", type:""}), this.getExamAudioCallback);
      },
      stopPlayVoice(){
        this.isPlayAudio = false;
        this.$JsBridge.callHandler('nativeStopVoice',JSON.stringify({backUrl:"LevelExamStart", type:""}), this.stopPlayVoiceCallback);
      },
      stopPlayVoiceCallback(){

      },
      stopVuePlayer(){
        this.$refs.videoPlayer.player.pause();
        /**
         * 播放中
         * 按下暫停 ，狀態改成 N，播放器 pause()
         * 播放器設置 進度為 0
         * 播放器 canPlay 觸發
         * 因為我聽力測驗要自動播放音檔，所以我沒有檔他
         * 假如進入 canPlay 那個 callback 就會自動播放
         */
        if (this.lastDom != null && this.lastDom != "") {
          this.lastDom.src = "assets/img/play_nostar.png";
          this.lastDom = null;
        }
      },
      // 開始播放音檔
      startPlayVoice(m3u8, dom) {
        if (m3u8 == undefined) return ;
        console.log("xxx", m3u8);
        console.log("xxx", dom);

        if (dom == undefined || dom == ""){
          this.masterAudioPic = true;
          this.apiName = "video_get_token";
          oAC.getAllRecord(oAC.getApiParam(this), {src: m3u8}).then(res =>{
            if (res.get_video_token != undefined && res.get_video_token.data != undefined && res.get_video_token.data.token != undefined) {
              var json = {
                  type: "application/x-mpegURL",
                  src : `${m3u8}?token=${res.get_video_token.data.token}`,
              };
              this.playerOptions.sources = [];
              this.playerOptions.sources.push(json);
              this.isGetAudioToken = true;

              if (this.lastDom != null && this.lastDom != "" && this.lastDom != dom) {
                this.lastDom.src = "assets/img/play_nostar.png";
              }
              this.lastDom = dom;


              if(this.$JsBridge.isiOS){
                this.$refs.videoPlayer.muted = true;
              }
            }
          }).catch((err)=> {
            oltConsole.log(err);
          });
        }
        else if (this.lastDom == dom ) {
          this.stopPlayVoice();
          this.stopVuePlayer();
        } 
        else {

          // 手動點選喇把，就不要再自動播放了
          this.examAudioM3u8 = [];
          this.masterAudioPic = false;


          this.apiName = "video_get_token";
          oAC.getAllRecord(oAC.getApiParam(this), {src: m3u8}).then(res =>{
            if (res.get_video_token != undefined && res.get_video_token.data != undefined && res.get_video_token.data.token != undefined) {
              var json = {
                  type: "application/x-mpegURL",
                  src : `${m3u8}?token=${res.get_video_token.data.token}`,
              };
              this.playerOptions.sources = [];
              this.playerOptions.sources.push(json);
              this.isGetAudioToken = true;


              if (this.lastDom != null && this.lastDom != "" && this.lastDom != dom) {
                this.lastDom.src = "assets/img/play_nostar.png";
              }
              this.lastDom = dom;


              if(this.$JsBridge.isiOS){
                this.$refs.videoPlayer.muted = true;
              }
            }
          }).catch((err)=> {
            oltConsole.log(err);
          });
        }
      },

      /**名稱: 答題音效
       * 描述: 答對或答錯要播放音效 , 在進入下一題
       * 用於: 等級測驗
       */
      getExamAudioCallback() {
        this.$JsBridge.callHandler('resetMediaPlayerVue',JSON.stringify({backUrl:"LevelExamStart", type:""}), this.resetMediaPlayerVueCallback);
        if (this.exam != undefined && this.exam.length > 0) {
          console.log("Step5");
            this.InitExam();
            this.RandomExam();
            this.UpdateExam();
        } else {
          this.openComplete();
        }
      },
      resetMediaPlayerVueCallback(){

      },
      openComplete(){
        this.$router.push({
          name: "LevelExamComplete",
          params:{
            huepid: this.form["huepid"],
            eplid: this.form["eplid"],
            dont: 'N',
            uid: this.$GlobalData.uid,
            back: "LevelExamIntro"
          }
        });
      },
      IDontKnowTimer(){
        var tempExam = this.exam;
        var initExam = this.InitExam;
        var randomExam = this.RandomExam;
        var updateExam = this.UpdateExam;
        var openComplete = this.openComplete;
        this.exam.splice(this.currIndex, 1);
        var mExam = {
          "egid": JSON.stringify(this.currExam.exam_group.egid),
          "eid": JSON.stringify(this.currExam.exam_list[0].exam.eid),
          "result_type": "id",
          "eiid": JSON.stringify(1)
        };

        this.form = {
          eplid: this.eplid,
          huepid: this.huepid,
          uid: this.$GlobalData.uid,
          exam: mExam
        };

        this.apiName="page_level_exam_start_answer";
        oAC.postRecord(oAC.getApiParam(this)).then((res)=> {
          // setTimeout(function() {
            if (tempExam != undefined && tempExam.length > 0) {
                initExam();
                randomExam();
                updateExam();
            } else {
              openComplete();
            }
          // }, 500);
        }).catch((err)=> {
          oltConsole.log(err);
        });
      },
      saveAnswer(){
        // 儲存作答紀錄
        if (this.recordEiidArray.size > 0) {
          var tempExam = [];
          var postExamAnswer2 = this.postExamAnswer2;
          var tempExamArr = [];
          this.form["uid"] = this.$GlobalData.uid;
          this.recordEiidArray.forEach((value_eid, key_eiid, item) => {
            tempExam = {
              "egid": JSON.stringify(this.currExam.exam_group.egid),
              "eid": JSON.stringify(value_eid),
              "eiid": JSON.stringify(key_eiid),
              "result_type": "id"
            };
            tempExamArr.push(tempExam);
          });

          postExamAnswer2(tempExamArr);
        }
      },
      showCorrectImageItem(){
        let playCorrectAudio = false;
        let tempCurrExam = this.currExam;  

        // 多少個小題
        for (var i = 0; i < this.currExam.exam_list.length; i++) {
          if (this.currExam.exam_list[i].answer != undefined && this.currExam.exam_list[i].answer.length > 0) {
            // 多少個答案
            for (var j = 0; j < this.currExam.exam_list[i].answer.length; j++) {
              // 加上正確的圖示
              if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                $("#ok"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("hide");
                // 綠色漸層
                let tempImage = $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).css("background-image");
                let imageSuccess = 'background-image: linear-gradient(to bottom, rgba(96, 255, 231, 0.4), rgba(96, 255, 231, 0.4)), '+tempImage; // Success

                for (var k = 0; k < this.currExam.exam_list.length; k++) {
                  for (var l = 0; l < this.currExam.exam_list[k].item.length; l++) {
                    if (this.currExam.exam_list[k].item[l].eiid == this.currExam.exam_list[i].answer[j]["eiid"]) {
                      this.currExam.exam_list[k].item[l].image_url = imageSuccess;
                    }
                  }
                }

                // 綠色邊框
                $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).addClass("greentestpicborder");

                playCorrectAudio = true;
              } 

              // 紀錄答案, 並刪除目前紀錄在 MAP 裡面的資料                    
              if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                this.recordEiidArray.set(this.currExam.exam_list[i].answer[j]["eiid"], this.currExam.exam_list[i].answer[j]["eid"]);
                this.recordExamItem.delete(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]));
              }
            }
          }
        }
 
        // 假如 MAP 裡面還有剩餘資料， 必須將它標示為錯誤的
        if (this.recordExamItem.size > 0) {
          this.recordExamItem.forEach((value_eid, key_eiid, items) => {
            // 紀錄答案
            this.recordEiidArray.set(key_eiid, value_eid);
            $("#fail"+key_eiid).removeClass("hide");
            $("#text"+key_eiid).removeClass("greentestpicborder");
            // 紅色漸層
            let tempImage = $("#text"+key_eiid).css("background-image");
            let imageError = 'background-image: linear-gradient(to bottom, rgba(255, 158, 158, 0.4), rgba(255, 158, 158, 0.4)), '+tempImage; // Error

            for (var i = 0; i < tempCurrExam.exam_list.length; i++) {
              for (var j = 0; j < tempCurrExam.exam_list[i].item.length; j++) {
                if (tempCurrExam.exam_list[i].item[j].eiid == key_eiid) {
                  tempCurrExam.exam_list[i].item[j].image_url = imageError;
                }
              }
            }

            // 紅色邊框
            $("#text"+key_eiid).addClass("redtestpicborder");
            playCorrectAudio = false;
          });

          this.currExam = tempCurrExam;
        }
        return playCorrectAudio;
      },
      showModal(target){
        $(target).modal("show");
      },


      // 播放器事件
      onPlayerPlay(player) {
          // console.log(`onPlayerPlay ==> ${player}`);
      },
      onPlayerPause(player) {
          // console.log(`onPlayerPause ==> ${player}`);
      },
      playerStateChanged(playerCurrentState) {
        // console.log(`playerStateChanged ==> ${playerCurrentState}`);
      },
      onPlayerEnded(player) {
        // console.log(`onPlayerEnded ==> ${player}`);
        this.isPlayAudio = false;

        if (this.masterAudioPic) {
          this.masterAudioPic = false;
        } else if (this.lastDom != null && this.lastDom != "") {
          this.lastDom.src = "assets/img/play_nostar.png";  
        }

        if (this.examAudioM3u8.length > 0) {
          this.currentAudio++;
          this.startPlayVoice(this.examAudioM3u8[0]);
          this.masterAudioPic = false;
          this.examAudioM3u8.shift();
        } else {
          this.currentAudio = -1;
        }
        this.lastDom = null;
      },
      onPlayerPlaying(player) {
        // console.log(`onPlayerPlaying ==> ${player}`);
      },
      onPlayerWaiting(player) {
        // console.log(`onPlayerWaiting ==> ${player}`);
      },
      onPlayerLoadeddata(player) {
        // console.log(`onPlayerLoadeddata ==> ${player}`);
      },
      onPlayerTimeupdate(player) {
        // console.log(`onPlayerTimeupdate ==> ${player}`);
      },
      onPlayerCanplay(player) {
        if (this.isShowHistory || this.isCheckAnswer || !this.isGetAudioToken || this.isPlayAudio || this.masterAudioPic || this.lastDom != "") {

        } else if (this.$JsBridge.isAndroid) {
          this.onPlay();
        }
      },
      onPlayerCanplaythrough(player) {
        // console.log(`onPlayerCanplaythrough ==> ${player}`);
      },
      // player is ready
      playerReadied(player) {
        // console.log('the player is readied', player)
        // you can use it to do something...
        // player.[methods]
        if (this.$JsBridge.isiOS) {
          setTimeout(this.onPlay, 300);
        }
      },
      onPlay(){
        if (this.$refs.videoPlayer.player != undefined) {
          this.$refs.videoPlayer.player.play();  
        }
        if (this.lastDom != undefined && this.lastDom != null && this.lastDom != "") {
          this.lastDom.src = "assets/img/play.png";
        }  
      },
      pauseVueAudio(){
        if (this.$refs.videoPlayer != undefined && this.$refs.videoPlayer.player != undefined) {
          this.$refs.videoPlayer.player.pause();
          if (this.lastDom != undefined && this.lastDom != null && this.lastDom != "") {
            this.lastDom.src = "assets/img/play_nostar.png";
          }
        }
      },


    },
    created(){
      if (this.$route.query.uid !=undefined) {
        this.$GlobalData.uid = this.$route.query.uid;
      }
      if (this.$GlobalData.uid == "") {
        this.openLogin();
        return false;
      }
      if (this.$JsBridge.isAndroid) {
        this.setOnBack();
      }
      this.getAPI();
    },
    mounted()
    { 
		
		this.config = config;
    }
};
</script>
<style>
  .addedHight{
    height: 25px;
  }
  .hide{
    display: none !important;
  }
  .examItemImageCheck{
    float: right; 
    background-position:  center; 
    background-size: cover;
    background-repeat: no-repeat; 
  }
  .lht2{
    line-height:2
  }
  .nicole09rem{
      font-size: .9rem  !important; 
      font-weight: 400;
  }
  .nicole1rem{
      font-size: 1rem  !important; 
      font-weight: 400;
  }
  .nicole11rem{
      font-size: 1.05rem  !important; 
      font-weight: 400;
  }
  .nicole12rem{
      font-size: 1.1rem  !important; 
      font-weight: 400;
  }
  .nicole13rem{
      font-size: 1.2rem  !important; 
      font-weight: 400;
  }
  .nicole14rem{
      font-size: 1.3rem  !important; 
      font-weight: 400;
  }
  .nicole15rem{
      font-size: 1.4rem  !important; 
      font-weight: 400;
  }
  .nweight5{
      font-weight: 500 !important;
  }
  .nweight6{
      font-weight: 600 !important;
  }
  .examItemImage{
    background-size:contain; 
    background-repeat: no-repeat; 
    background-position:center;
  }


  body{
    color: #333;
  }

  .badge-secondary span,
  .badge-secondary {
    color: #43494e;
  }
  @import '../../../../public/assets/css/admin-customize.css';
</style>
